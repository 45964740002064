import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.summary}
      />
      <div className="container update-item">
        <div className="max-w-2xl mx-auto py-12">
          <h1 className="text-4xl font-bold mb-4">{frontmatter.title}</h1>
          <div className="mb-4 text-gray-800">{frontmatter.date}</div>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      html
      frontmatter {
        date
        title
        expenses
        summary
      }
    }
  }
`